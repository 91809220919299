import React from 'react';
import hash from './uniqueHash';

// import { LeftMenuSettingsIcon } from '../icons';
import {
    EZLOGIC_TITLE_CONTROLLER_NAME,
    EZLOGIC_TITLE_DEVICE_NAME_SMALL,
    EZLOGIC_TITLE_MESHBOT_TEMPLATE,
    EZLOGIC_TITLE_ROOM_NAME,
    EZLOGIC_TITLE_SYSTEM_TEMPLATE,
} from './language_tokens';
import { Ezlogic, LeftMenuSettingsIcon } from '../assets/icons';

export const MAX_LENGTH_BODY = 10000;
export const MAX_LENGTH_SUBJECT = 48;
export const CATEGORIES = 'Categories';
export const CHANNELS = 'Channels';
export const USERS = 'Users';
export const COPIED = 'copied';
export const SYSTEM = 'system';
export const MESHBOT_TEMPLATE = 'meshbot';
export const SELECT_ALL = 'Select All';
export const MESSAGE_BODY = 'Message body';
export const NOTIFICATION_TEMPLATE_KEYWORDS = [
    { name: EZLOGIC_TITLE_DEVICE_NAME_SMALL, value: '((abstract.{abstract.uuid}.name))' },
    { name: EZLOGIC_TITLE_ROOM_NAME, value: '((abstract_meta.{abstract.uuid}.parent_room_name))' },
    { name: EZLOGIC_TITLE_CONTROLLER_NAME, value: '((abstract_meta.{abstract.uuid}.parent_controller_name))' },
];

export const NOTIFICATIONS_TEMPLATE_TYPES = [
    {
        id: hash(),
        name: 'meshbot',
        title: EZLOGIC_TITLE_MESHBOT_TEMPLATE,
        icon: <Ezlogic />,
    },
    {
        id: hash(),
        name: 'system',
        title: EZLOGIC_TITLE_SYSTEM_TEMPLATE,
        icon: <LeftMenuSettingsIcon />,
    },
];

export const OEM_EVENT_TYPES = {
    SYSTEM: {
        SYSTEM_RESET_PASSWORD_EMAIL: 'system_reset_password_email',
        SYSTEM_RESET_PASSWORD_EMAIL_LINK: 'system_reset_password_email_link',
        SYSTEM_NEW_USER: 'system_legacy_send_new_user_info_to_user',
        SYSTEM_NEW_INSTALLER: 'system_legacy_send_new_user_info_to_installer',
        SYSTEM_NEW_PARTNER: 'system_legacy_send_new_user_info_to_partner',
        VALIDATE_ACCOUNT_EMAIL: 'system_validate_account_email',
        SYSTEM_BURGLAR_ALARM: 'system_protect_alert_start_alert_burglary',
        SYSTEM_CO_ALARM: 'system_protect_alert_start_alert_variable',
        SYSTEM_FIRE_ALARM: 'system_protect_alert_start_alert_fire',
        SYSTEM_FIRE_PANIC_BUTTON: 'system_protect_panic_fire',
        SYSTEM_MEDICAL_PANIC_BUTTON: 'system_protect_panic_medical',
        SYSTEM_POLICE_PANIC_BUTTON: 'system_protect_panic_police',
        SYSTEM_ASSISTANCE_REQUEST_CANCELLED: 'system_protect_panic_cancel',
        SYSTEM_ASSISTANCE_REQUEST_VERIFIED: 'system_protect_panic_dispatch',
        SYSTEM_TEST_MODE_ON: 'system_protect_test_mode_on',
        SYSTEM_TEST_MODE_OFF: 'system_protect_test_mode_off',
        SYSTEM_SELF_DELETE_CONFIRMATION_EMAIL: 'system_self_delete_confirmation_email',
    },
    CAPABILITY_NAME: {
        SYSTEM_NEW_USER: 'New User Information',
        SYSTEM_NEW_INSTALLER: 'New Installer Information',
        SYSTEM_NEW_PARTNER: 'New Partner Information',
        VALIDATE_ACCOUNT_EMAIL: 'Email validation',
        SYSTEM_RESET_PASSWORD_EMAIL: 'Password has been reset',
        SYSTEM_RESET_PASSWORD_EMAIL_LINK: 'Password reset requested',
        SYSTEM_BURGLAR_ALARM: 'Burglar alarm',
        SYSTEM_CO_ALARM: 'CO alarm',
        SYSTEM_FIRE_ALARM: 'Fire alarm',
        SYSTEM_FIRE_PANIC_BUTTON: 'Fire panic button',
        SYSTEM_MEDICAL_PANIC_BUTTON: 'Medical panic button',
        SYSTEM_POLICE_PANIC_BUTTON: 'Police panic button',
        SYSTEM_ASSISTANCE_REQUEST_CANCELLED: 'Assistance request cancelled',
        SYSTEM_ASSISTANCE_REQUEST_VERIFIED: 'Assistance request verified',
        SYSTEM_TEST_MODE_ON: 'Test mode turned on',
        SYSTEM_TEST_MODE_OFF: 'Test mode turned off',
        SYSTEM_SELF_DELETE_CONFIRMATION_EMAIL: 'Account Deletion Confirmation',
    },
};
export const SYSTEM_EVENTS_CAPABILITIES = [
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_NEW_USER,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_NEW_USER,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_NEW_INSTALLER,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_NEW_INSTALLER,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_NEW_PARTNER,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_NEW_PARTNER,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.VALIDATE_ACCOUNT_EMAIL,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.VALIDATE_ACCOUNT_EMAIL,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_RESET_PASSWORD_EMAIL,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_RESET_PASSWORD_EMAIL,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_RESET_PASSWORD_EMAIL_LINK,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_RESET_PASSWORD_EMAIL_LINK,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_BURGLAR_ALARM,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_BURGLAR_ALARM,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_CO_ALARM,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_CO_ALARM,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_FIRE_ALARM,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_FIRE_ALARM,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_FIRE_PANIC_BUTTON,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_FIRE_PANIC_BUTTON,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_MEDICAL_PANIC_BUTTON,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_MEDICAL_PANIC_BUTTON,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_POLICE_PANIC_BUTTON,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_POLICE_PANIC_BUTTON,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_ASSISTANCE_REQUEST_CANCELLED,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_ASSISTANCE_REQUEST_CANCELLED,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_ASSISTANCE_REQUEST_VERIFIED,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_ASSISTANCE_REQUEST_VERIFIED,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_TEST_MODE_ON,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_TEST_MODE_ON,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_TEST_MODE_OFF,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_TEST_MODE_OFF,
        id: hash(),
    },
    {
        value: OEM_EVENT_TYPES.SYSTEM.SYSTEM_SELF_DELETE_CONFIRMATION_EMAIL,
        capability_name: OEM_EVENT_TYPES.CAPABILITY_NAME.SYSTEM_SELF_DELETE_CONFIRMATION_EMAIL,
        id: hash(),
    },
];

export const FORCE_SEND_EMAIL_CAPABILITIES = [
    OEM_EVENT_TYPES.SYSTEM.SYSTEM_RESET_PASSWORD_EMAIL_LINK,
    OEM_EVENT_TYPES.SYSTEM.SYSTEM_RESET_PASSWORD_EMAIL,
];
export const PUSH_META = 'push_meta';
